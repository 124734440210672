import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import Head from 'next/head';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import Header from './header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import NProgress from 'nprogress';
import { useFirestore } from 'hooks/useFirestore';
import AlertDialog from 'components/molecules/AlertDialog';
import Footer from './header/Footer';
import useLocalStorage from 'hooks/useLocalStorage';
import { cartFirestoreActions } from 'redux/actions/firestore/cart';
import { entries, forEach, throttle, values } from 'lodash';
import Headroom from 'react-headroom';
import { useHeadroom } from 'context/headroom';
import CartMobile from 'components/specific/CartMobile';
import dynamic from 'next/dynamic';
import { IMetaTags } from 'static/meta';
import { useCurrentLocationContext } from 'context/location';
import { useRouting } from 'services/routing';
import { useRouter } from 'next/router';
import { GenericDialog } from 'components/generic-dialog/GenericDialog';
import ModalPopup from 'components/molecules/ModalPopup';
import ReviewModal from './profile/components/ReviewModal';
import { Customer, CustomerAddress, Order } from 'klikni-jadi-shared-stuff';
import { ordersFirestoreActions } from 'redux/actions/firestore/orders';
import { generateId, updateDoc } from 'services/db/firestoreApi';
import { useLocationContext } from 'context/current-location';
import { useCartService } from 'services/cart/useCartService';
import { _generateToken } from 'services/cart/useCartService/token';
import { useRest } from 'hooks/useRest';
import TawkTo from 'tawkto-react';
import * as Sentry from '@sentry/nextjs';
import { hotjar } from 'react-hotjar';
import { HJID, HJSV } from 'config/hotjar';
import { isServerSide } from 'services/utils';
import BrowserSuggestion from './login/login-flow/BrowserSuggestion';
import { findRegionByLocation } from 'services/location/findRegionByLocation';
import { stateIsLoaded, stateIsNotReady } from 'services/stateHelpers';
import { geocodeLocation } from 'services/location/geocodeLocation';

const LoginModal = dynamic(() => import('containers/login/LoginModal'), { ssr: false });

interface LayoutProps {
    children: React.ReactChild;
    showHeaderSearch?: boolean;
    transparentSearch?: boolean;
    isLandingPage?: boolean;
    routerQuery?: any;
    showFooter?: boolean;
    url?: string;
    metaTags: IMetaTags;
    minHeight?: boolean;
    // metaKey?: string;
    // dynamicMeta?: {
    //     title: string;
    // };
}

const Layout: FunctionComponent<LayoutProps> = ({
    children,
    transparentSearch = false,
    routerQuery,
    showFooter = true,
    url,
    metaTags,
    isLandingPage,
    minHeight = false,
    // metaKey = 'homepage',
    // dynamicMeta = staticMetaContent.homepage,
}) => {
    const dispatch = useDispatch();
    const { pinHeader, unpinHeader } = useHeadroom();
    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('md'));
    const user = useSelector((state: RootState) => state.currentUser);
    const orders = useSelector((state: RootState) => state.userOrders);
    const { addAddressToHost, cartId: _cartId } = useCartService();
    const cart = useSelector((state: RootState) => state.userCart);

    const { data: location, update } = useLocationContext();
    const routing = useRouting();
    const router = useRouter();
    const [openReviewOrder, setOpenReviewOrder] = useState<Order>(null);
    const firestore = useFirestore('orders');
    const { carts } = useRest();

    // useEffect(() => {
    //     Sentry.configureScope(scope => {
    //         scope.setExtra('battery', 0.7);
    //         scope.setTag('user_mode', 'admin');
    //         scope.setUser({ id: '4711' });
    //         scope.clear();
    //     });
    // }, []);

    useEffect(() => {
        hotjar.initialize(+HJID, +HJSV);
    }, []);

    useEffect(() => {
        if (user?.data?.id) {
            hotjar.identify(user?.data?.email, {
                userId: user?.data?.id,
                userCartId: user?.data?.cartId,
                region: routerQuery?.city,
            });
        }
    }, [user?.data?.id, routerQuery]);

    useEffect(() => {
        let tawk = new TawkTo('63e6626f4742512879129dcc', '1gotv10ua');
        tawk.onStatusChange(status => {
            console.log(status);
        });
    }, []);

    useEffect(() => {
        if ((!location?.location && !user.data) || (!location?.location && user?.data && !routerQuery?.a)) {
            if (routerQuery?.nearby) {
                const arr = routerQuery?.nearby.split(',');
                const longitude = parseFloat(arr[1]);
                const latitude = parseFloat(arr[0]);
                findRegionByLocation(latitude, longitude).then(region => {
                    if (
                        latitude === region?.location?.position?.latitude &&
                        longitude === region?.location?.position?.longitude
                    ) {
                        update('__data__', {
                            location: {
                                latitude,
                                longitude,
                                fullAddress: region.title,
                            },
                            region: region?.id || null,
                            locationType: 'city',
                        });
                    } else {
                        geocodeLocation(latitude, longitude).then(({ city, currentAddress }) => {
                            update('__data__', {
                                location: {
                                    latitude,
                                    longitude,
                                    fullAddress: currentAddress.fullAddress,
                                },
                                region: city || null,
                                locationType: 'address',
                            });
                        });
                    }
                });
            }
        } else {
            if (user?.data?.id && cart?.data?.id) {
                if (location?.location && !routerQuery?.a && cart?.data?.id) {
                    const addressId = generateId('customers');
                    const customerAddress: CustomerAddress = {
                        fullAddress: location?.location?.fullAddress,
                        location: {
                            latitude: location?.location?.latitude,
                            longitude: location?.location?.longitude,
                        },
                        approved: false,
                        isDefault: false,
                        instructions: '',
                    };
                    if (user?.data?.addresses) {
                        if (location?.locationType === 'city') {
                            const _addresses = entries(user.data.addresses);
                            const regionAddresses = _addresses.filter(([, a]) => a.region === location.region);
                            let userAddress: CustomerAddress = null;
                            let id: string = null;
                            if (regionAddresses.length > 0) {
                                const primary = regionAddresses.find(([, a]) => a.isDefault);
                                if (primary) {
                                    userAddress = primary[1];
                                    id = primary[0];
                                } else {
                                    userAddress = regionAddresses[0][1];
                                    id = regionAddresses[0][0];
                                }
                            }

                            if (userAddress) {
                                const payload = {
                                    update: 'address',
                                    data: { customerId: cart?.data?.host?.id, addressId: id },
                                };
                                carts.patch(payload as any, cart?.data?.id);
                                update('__data__', {
                                    location: {
                                        latitude: userAddress.location.latitude,
                                        longitude: userAddress.location.longitude,
                                        fullAddress: userAddress.fullAddress,
                                    },
                                    region: location.region || null,
                                    locationType: 'address',
                                });
                                if (router.asPath.includes('/partners')) {
                                    routing.push({
                                        queries: {
                                            nearby: `${[userAddress.location?.latitude, userAddress.location?.longitude].join(
                                                ','
                                            )}`,
                                            city: location.region,
                                        },
                                    });
                                }
                            }
                        } else {
                            const entry = entries(user.data.addresses).find(
                                ([, a]) =>
                                    a.location.latitude === customerAddress.location.latitude &&
                                    a.location.longitude === customerAddress.location.longitude
                            );
                            _generateToken(cart?.data?.id).then(_token => {
                                if (cart?.data?.host?.id) {
                                    if (entry) {
                                        const [id] = entry;
                                        const payload = {
                                            update: 'address',
                                            data: { customerId: cart?.data?.host?.id, addressId: id },
                                        };
                                        carts.patch(payload as any, cart?.data?.id, { 'x-access-token': _token });
                                    } else {
                                        const payload = {
                                            update: 'address',
                                            data: { customerId: cart?.data?.host?.id, addressId: addressId },
                                        };
                                        updateDoc('customers', user?.data?.id, {
                                            addresses: {
                                                [addressId]: customerAddress,
                                            },
                                        } as Customer).then(() => {
                                            carts.patch(payload as any, cart?.data?.id, { 'x-access-token': _token });
                                        });
                                    }
                                }
                            });
                        }
                    }
                }
            }
        }
    }, [user?.data?.id, cart?.data?.host?.id, location?.location?.latitude, location?.location?.longitude]);

    useEffect(() => {
        if (user?.data?.id) {
            firestore.collection(ordersFirestoreActions, {
                listen: true,
                queries: [
                    {
                        attribute: 'customer.id',
                        operator: '==',
                        value: user?.data?.id,
                    },
                ],
                sort: { attribute: 'createdAt', order: 'desc' },
                listenerName: 'user-orders',
            });

            // if (location?.location && !routerQuery?.a && cart?.data?.id) {
            //     const addressId = generateId('customers');
            //     const customerAddress: CustomerAddress = {
            //         fullAddress: location?.location?.fullAddress,
            //         location: {
            //             latitude: location?.location?.latitude,
            //             longitude: location?.location?.longitude,
            //         },
            //         approved: false,
            //         isDefault: false,
            //     };
            //     if (user?.data?.addresses) {
            //         if (location?.locationType === 'city') {
            //             const _addresses = entries(user.data.addresses);
            //             const regionAddresses = _addresses.filter(([, a]) => a.region === location.region);
            //             let userAddress = null;
            //             let id = null;
            //             if (regionAddresses.length > 0) {
            //                 const primary = regionAddresses.find(([, a]) => a.isDefault);
            //                 if (primary) {
            //                     userAddress = primary[1];
            //                     id = primary[0];
            //                 } else {
            //                     userAddress = regionAddresses[0];
            //                 }
            //             }

            //             if (userAddress) {
            //                 const payload = {
            //                     update: 'address',
            //                     data: { customerId: cart?.data?.host?.id, addressId: id },
            //                 };
            //                 carts.patch(payload as any, cart?.data?.id);
            //             }
            //         } else {
            //             const entry = entries(user.data.addresses).find(
            //                 ([, a]) =>
            //                     a.location.latitude === customerAddress.location.latitude &&
            //                     a.location.longitude === customerAddress.location.longitude
            //             );
            //             _generateToken(cart?.data?.id).then(_token => {
            //                 if (cart?.data?.host?.id) {
            //                     if (entry) {
            //                         const [id] = entry;
            //                         const payload = {
            //                             update: 'address',
            //                             data: { customerId: cart?.data?.host?.id, addressId: id },
            //                         };
            //                         carts.patch(payload as any, cart?.data?.id, { 'x-access-token': _token });
            //                     } else {
            //                         const payload = {
            //                             update: 'address',
            //                             data: { customerId: cart?.data?.host?.id, addressId: addressId },
            //                         };
            //                         updateDoc('customers', user?.data?.id, {
            //                             addresses: {
            //                                 [addressId]: customerAddress,
            //                             },
            //                         } as Customer).then(() => {
            //                             carts.patch(payload as any, cart?.data?.id, { 'x-access-token': _token });
            //                         });
            //                     }
            //                 }
            //             });
            //         }
            //     }
            // }
        } else {
            dispatch(ordersFirestoreActions.reset());
        }
        return () => {
            firestore.unsubscribe('user-orders');
        };
    }, [user?.data?.id, cart?.data?.host?.id]);

    const unreviewedOrders = useMemo(() => {
        if (orders?.data?.length > 0) {
            return orders.data.filter(order => !order?.reviewPopupShown && order.status === 'delivered');
        } else {
            return [];
        }
    }, [orders]);

    useEffect(() => {
        if (unreviewedOrders?.length > 0) {
            setOpenReviewOrder(unreviewedOrders[0]);
        }
    }, [unreviewedOrders]);

    const globals = useSelector((state: RootState) => state.globals.data);
    const [cartId] = useLocalStorage('_cart_id');
    const cartFirestore = useFirestore('carts');

    const cartListener = useRef<string>('');

    useEffect(() => {
        const callback = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        window.addEventListener('resize', throttle(callback, 500));
        return () => {
            window.removeEventListener('resize', throttle(callback, 500));
        };
    }, []);

    // useEffect(() => {
    //     if (!cartId) {
    //         if (!cartIdTaken.current) {
    //             if (!currentUser?.cartId) {
    //                 cartService.createCart().then(response => {
    //                     setCartId(response.data);
    //                     console.log('cart response', response);
    //                     cartIdTaken.current = true;
    //                 });
    //             }
    //         }
    //     }
    // }, [currentUser]);

    useEffect(() => {
        if (cartId && cartId !== 'undefined') {
            cartListener.current = `cart_listener-${cartId}`;
            cartFirestore.doc(cartId, cartFirestoreActions, { listen: true, listenerName: cartListener.current });
        } else {
            cartFirestore.unsubscribe(cartListener.current);
            cartListener.current = '';
            dispatch(cartFirestoreActions.reset());
            // cartService.createCart().then(res => {
            //     setCartId(res.data);
            // });
        }
        return () => {
            cartFirestore.unsubscribe(cartListener.current);
            cartListener.current = '';
        };
    }, [cartId, dispatch]);

    // useEffect(() => {
    //     const persistedGroupCartId = getPersistedGroupCartId();
    //     if (persistedGroupCartId?.id) {
    //         groupCarts.doc(persistedGroupCartId?.id, groupCartInvitedFirestoreActions, {
    //             listen: true,
    //         });
    //     }
    // }, []);

    // useEffect(() => {
    //     const items = getPersistedItems();
    //     if (items.length) dispatch(setCartItems(items));
    // }, []);

    // useEffect(() => {
    //     if (stateIsLoaded(groupCart) && groupCart.data && groupCart.data.length > 0) {
    //         const groupUsers = groupCart.data[0].users;
    //         if (stateIsLoaded(groupCartInvited) && groupCartInvited.data) {
    //             if (currentUser.data) {
    //                 const mine = groupUsers.find(u => u.id === currentUser.data.id);
    //                 mine.cart = cart.data;
    //             }
    //         }
    //         groupCartsQuery.set(groupCart.data[0].id, {
    //             ...groupCart.data[0],
    //             hostItems: cart.data.items,
    //             users: groupUsers,
    //         });
    //     }
    //     if (cart.data.items.length > 0) persistItems(cart.data.items);
    // }, [cart]);

    // useEffect(() => {
    //     if (stateHasFailed(groupCartInvited)) {
    //         // routing.push({ queries: { gcid: false } });
    //         removePersistedGroupCartId();
    //     }
    //     if (stateIsLoaded(groupCartInvited) && groupCartInvited.data) {
    //         persistGroupCartIdCreationDate(groupCartInvited.data.createdAt.toMillis());
    //         if (currentUser.data) {
    //             const allUsers = groupCartInvited.data.users;
    //             const user = allUsers.find(u => u.id === currentUser.data.id);
    //             if (!user) {
    //                 groupCartsQuery.set(groupCartInvited.data.id, {
    //                     users: [
    //                         ...allUsers,
    //                         {
    //                             id: currentUser.data.id,
    //                             lastName: currentUser.data.lastName || '',
    //                             name: currentUser.data.name,
    //                         },
    //                     ],
    //                 });
    //             }
    //         }
    //     }
    // }, [groupCartInvited]);

    // useEffect(() => {
    //     if (routerQuery.gcid) {
    //         const persisted = persistGroupCartId(routerQuery.gcid);
    //         if (persisted) {
    //             groupCartsQuery.doc(routerQuery.gcid, groupCartInvitedFirestoreActions, {
    //                 listen: true,
    //             });
    //             alert({
    //                 title: 'You are now in a group cart. Everything that you order will go to the shared cart of the host.',
    //                 successText: 'OK',
    //                 cancelable: false,
    //                 illustration: groupCartIllustration,
    //             });
    //         }
    //     }
    // }, [routerQuery]);

    // useEffect(() => {
    //     const unsubscribe = firebase.auth().onAuthStateChanged((user: firebase.User) => {
    //         if (user) {
    //             firestore.doc(user.uid, currentUserFirestoreActions, { listen: true });
    //         } else {
    //             dispatch(currentUserFirestoreActions.reset());
    //         }
    //     });
    //     return unsubscribe;
    // }, []);

    // useEffect(() => {
    //     if (currentUser.data && !stateIsLoaded(groupCart)) {
    //         groupCarts.collection(groupCartFirestoreActions, {
    //             queries: [
    //                 {
    //                     attribute: 'host',
    //                     operator: '==',
    //                     value: currentUser.data.id,
    //                 },
    //             ],
    //             listen: true,
    //         });
    //     }
    // }, [currentUser.data]);

    useEffect(() => {
        NProgress.done();
    }, []);

    // useEffect(() => {
    //     dispatch(setIsMapLoaded(isLoaded));
    // }, [isLoaded]);

    // useEffect(() => {
    //     dispatch(setLoadError(loadError));
    // }, [loadError]);

    // const handle: UIEventHandler<HTMLDivElement> = event => {
    //     if (event.currentTarget?.scrollTop) {
    //         dispatch(scrollOffsetAction(event.currentTarget?.scrollTop));
    //     }
    // };
    const handleCloseReview = async () => {
        await updateDoc('orders', openReviewOrder.id, { reviewPopupShown: true });
    };

    const [inAppBrowserDetected, setInAppBrowserDetected] = useState<boolean>();
    useEffect(() => {
        if (isServerSide()) return;
        // Redirect from Embedded browser to default browser.
        const ua = window.navigator.userAgent || window.navigator.vendor;
        const inAppBrowsers = [
            'FBAN',
            'FBAV',
            'Instagram',
            'WhatsApp',
            'Discord',
            'Viber',
            'Slack',
            'TikTok',
            'LinkedIn',
            'Twitter',
            'Pinterest',
            'Snapchat',
            'Telegram',
            'Line',
            'MicroMessenger',
            'Reddit',
        ];
        const _inAppBrowserDetected = inAppBrowsers.some(function (browser) {
            return ua.indexOf(browser) > -1;
        });
        setInAppBrowserDetected(_inAppBrowserDetected);

        const notInIOS = !/iPad|iPhone|iPod/.test(window.navigator.userAgent); // && !window.MSStream
        if (_inAppBrowserDetected && notInIOS) {
            window.location.href = 'https://takeawaymk.page.link/open-browser';
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: minHeight ? '100vh' : 'none',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            {/* <Box height="100vh" overflow="auto" id="layout-container-scrollable-div" onScroll={handle}> */}
            <Head>
                {/* <title>{metaKey === 'dynamic' ? dynamicMeta.title : staticMetaContent[metaKey].title}</title> */}
                <title>{metaTags.title}</title>
                <link rel="icon" href={metaTags.image} />
                {/* <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/4.6.1/firebase-ui-auth.css" /> */}

                <meta property="og:title" content={metaTags.title} />
                <meta property="og:site_name" content={metaTags.siteName} />
                <meta property="og:url" content={metaTags.url} />
                <meta property="og:description" content={metaTags.description} />
                <meta property="og:image" content={metaTags.image} />
                <meta property="og:type" content={metaTags.websiteType} />
                <meta name="fb:app_id" content={metaTags.fbAppId} />
                <meta name="facebook-domain-verification" content={metaTags.facebookDomainVerification} />

                <meta name="twitter:title" content={metaTags.title} />
                <meta name="twitter:description" content={metaTags.description} />
                <meta name="twitter:site" content={metaTags.siteName} />
                <meta name="twitter:image" content={metaTags.image} />

                <meta name="description" content={metaTags.description} />
                <meta name="keywords" content={metaTags.keywords} />
                <meta name="author" content={metaTags.author} />
                <script>{`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1230305340897991');
fbq('track', 'PageView');`}</script>
                <noscript>
                    <img
                        height="1"
                        width="1"
                        style={{ display: 'none' }}
                        src="https://www.facebook.com/tr?id=1230305340897991&ev=PageView&noscript=1"
                    />
                </noscript>

                <meta name="apple-itunes-app" content={metaTags.appleItunesApp} />
            </Head>

            {/* <Render when={transparentSearch && matchesBreakpoint}>
                <div className="app-header-container">
                    <Header transparentSearch={transparentSearch} routerQuery={routerQuery} url={url} />
                </div>
            </Render> */}

            {/* <Render when={!transparentSearch || !matchesBreakpoint}> */}
            <Headroom
                onPin={() => {
                    pinHeader();
                }}
                onUnpin={() => {
                    unpinHeader();
                }}
            >
                <Header isLandingPage={isLandingPage} transparentSearch={transparentSearch} routerQuery={routerQuery} url={url} />
            </Headroom>
            {/* </Render> */}

            <Box
                sx={{
                    position: 'relative',
                    zIndex: globals.isGalleryOpen ? 20000 : 99,
                    // marginTop: transparentSearch ? '-100px' : matched ? '-30px' : '-10px',
                    marginTop: transparentSearch ? '-100px' : 0,
                }}
            >
                {children}
                <Box
                    sx={{
                        display: {
                            xs: 'block',
                            lg: 'none',
                        },
                    }}
                    position="sticky"
                    bottom={0}
                    zIndex={99999}
                >
                    <CartMobile routerQuery={routerQuery} />
                </Box>
            </Box>
            <LoginModal inAppBrowserDetected={inAppBrowserDetected} />
            {showFooter && <Footer isLandingPage={isLandingPage} />}
            <AlertDialog />
            <GenericDialog />
            <ModalPopup open={unreviewedOrders?.length > 0} handleClose={handleCloseReview} minWidth="auto">
                <Box maxWidth="500px" height={{ xs: 'calc(var(--vh, 1vh) * 100)', md: '100%' }} sx={{ overflow: 'scroll' }}>
                    {openReviewOrder && (
                        <ReviewModal
                            date={openReviewOrder?.createdAt}
                            items={openReviewOrder?.items}
                            orderId={openReviewOrder?.id}
                            orderNo={openReviewOrder?.orderCode?.short}
                            restaurantId={openReviewOrder?.partner.id}
                            onClose={handleCloseReview}
                            foodMenuId={openReviewOrder?.foodMenuId}
                            restaurantName={openReviewOrder?.partner?.name}
                        />
                    )}
                </Box>
            </ModalPopup>
            {/* </Box> */}

            {inAppBrowserDetected && <BrowserSuggestion />}
        </Box>
    );
};

export default Layout;
