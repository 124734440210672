import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import { CustomerAddress } from 'klikni-jadi-shared-stuff';
import React, { useEffect, useState } from 'react';
import { ModalPopup } from 'components/molecules/ModalPopup';
import { PrimaryButton, BaseButton } from './atoms/Buttons';
import { BasicInput } from './atoms/Inputs';
import { MediumText, NormalText, SmallText } from './atoms/Typography';
import KlikAutocomplete from './organisms/KlikAutocomplete';
import KlikMap from './organisms/KlikMap';
import firebase from 'config/firebase';
import useTranslation from 'next-translate/useTranslation';
import DividerWithText from './molecules/DividerWithText';
import icon from 'assets/svg/currentLocationBlue.svg';
import { GeocoderService } from 'services/mapService';
import { isEqual } from 'lodash';
import { Location, MapMarker } from 'types/map';
import AddressMap from './Maps/AddressMap';
import AutocompleteAddress from './Maps/AutocompleteAddress';
import { LatLng } from 'leaflet';

interface IUserAddressMapProps {
    address: [string, CustomerAddress];
    open: boolean;
    onClose: () => void;
    onSave: (address: [string, CustomerAddress]) => void;
    inCart?: boolean;
}

const defaultAddress: CustomerAddress = {
    city: '',
    fullAddress: '',
    accommodation: 'apartment',
    apartmentNumber: '/',
    doorPin: '/',
    entranceNumber: '/',
    location: new firebase.firestore.GeoPoint(41.9981, 21.4254),
    streetNumber: '/',
    title: '',
    floor: '/',
    approved: false,
    isDefault: false,
    createdAt: null,
    instructions: '',
};

const UserAddressMap = ({ address, open, onClose, onSave, inCart }: IUserAddressMapProps) => {
    const [openedLocation, setOpenedLocation] = useState<[string, CustomerAddress]>([null, defaultAddress]);
    const { t } = useTranslation('common');
    const geocoderService = new GeocoderService();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        console.log('HERE');
        if (address && address[1]) setOpenedLocation(address);
    }, [address]);
    console.log('opened: ', openedLocation);

    const handleInputChange = name => e => {
        setOpenedLocation([openedLocation[0], { ...openedLocation[1], [name]: e.target.value }]);
    };

    // if (!openedLocation) return null;

    const handleAddressChangeMap = (t: {
        address: {
            fullAddress: string;
            latitude: number;
            longitude: number;
        };
    }) => {
        // dispatch(updateUsersLoginInfo({ address }));
        const { address } = t;
        if (address)
            setOpenedLocation([
                openedLocation[0],
                {
                    ...openedLocation[1],
                    location: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
                },
            ]);
    };

    const handlePositionChange = (position: LatLng) => {
        setOpenedLocation([
            openedLocation[0],
            {
                ...openedLocation[1],
                location: new firebase.firestore.GeoPoint(position.lat, position.lng),
            },
        ]);
    };

    const handleAddressChangeAutocomplete = (t: {
        address: {
            fullAddress: string;
            latitude: number;
            longitude: number;
            city: string;
            streetNumber?: string;
        };
    }) => {
        console.log('Search handle', t);
        console.log('Search handle2', openedLocation[1]);
        // dispatch(updateUsersLoginInfo({ address }));
        const { address } = t;
        if (address)
            setOpenedLocation([
                openedLocation[0],
                {
                    ...openedLocation[1],
                    fullAddress: address.fullAddress,
                    location: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
                    city: address.city,
                    streetNumber: address.streetNumber || '', //openedLocation[1].streetNumber,
                },
            ]);
    };

    const handleClose = () => {
        if (!inCart) {
            setOpenedLocation([null, defaultAddress]);
        }

        if (onClose) onClose();
    };

    const handleSave = () => {
        if (!inCart) {
            setOpenedLocation([null, defaultAddress]);
        }
        if (onSave) onSave([openedLocation[0], openedLocation[1]]);
    };

    const useCurrentLocation = () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
            timeout: 5000,
            enableHighAccuracy: true,
        });
    };
    const successCallback = position => {
        const { latitude, longitude } = position.coords;

        geocoderService.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (results.length) {
                    let found = results.find(add => add.types.includes('street_address') || add.types.includes('route'));
                    if (!found) {
                        found = results[0];
                    }

                    const city = found.address_components.find(el => isEqual(el.types, ['locality', 'political']));
                    const streetNumber = found?.address_components?.find(el => isEqual(el.types, ['street_number']))?.short_name;

                    setOpenedLocation([
                        openedLocation[0],
                        {
                            ...openedLocation[1],
                            city: city.long_name,
                            fullAddress: found.formatted_address,
                            location: new firebase.firestore.GeoPoint(latitude, longitude),
                            streetNumber: streetNumber || '',
                        },
                    ]);
                    setLoading(false);
                }
            }
        );
    };
    const errorCallback = e => {
        console.log(e);
        setLoading(false);
    };

    return (
        <ModalPopup open={open} handleClose={handleClose} maxHeight="100vh" maxWidth="100vw">
            <Box
                height={{ xs: 'calc(var(--vh, 1vh) * 100)', md: '90vh' }}
                width={{ xs: '100vw', sm: '80vw' }}
                display="flex"
                style={{ backgroundColor: 'white' }}
                flexDirection={{ xs: 'column', md: 'row' }}
            >
                <Box width={{ xs: '100%', md: '60%' }} height={{ xs: 250, md: 'unset' }}>
                    {/* <KlikMap
                        location={
                            openedLocation
                                ? {
                                      lat: openedLocation[1]?.location?.latitude,
                                      lng: openedLocation[1]?.location?.longitude,
                                  }
                                : null
                        }
                        zoom={17}
                        onClick={handleAddressChangeMap}
                    /> */}
                    <AddressMap
                        position={
                            openedLocation
                                ? ({
                                      lat: openedLocation[1]?.location?.latitude,
                                      lng: openedLocation[1]?.location?.longitude,
                                  } as Location)
                                : null
                        }
                        onPositionChange={handlePositionChange}
                    />
                </Box>
                <Box flex={1} pb={2} pt={0} px={2} mt={{ xs: 0, md: 1 }} display="flex" flexDirection="column" minHeight={0}>
                    <Box overflow="auto" flex={1} minHeight={0}>
                        <Box mt={2}>
                            <MediumText semibold>
                                <label>{t('addressTitle')}</label>
                            </MediumText>
                            <BasicInput
                                placeholder={t('title')}
                                initialValue={openedLocation[1]?.title}
                                onInput={handleInputChange('title')}
                                value={openedLocation[1]?.title}
                            />
                        </Box>
                        <Box mt={2}>
                            <DividerWithText>{t('info')}</DividerWithText>
                        </Box>
                        <Box mt={1} zIndex={999}>
                            <SmallText>
                                <label>{t('fullAddress')}</label>
                            </SmallText>

                            <KlikAutocomplete
                                address={{
                                    fullAddress: openedLocation[1]?.fullAddress,
                                    longitude: openedLocation[1]?.location?.longitude,
                                    latitude: openedLocation[1]?.location?.latitude,
                                }}
                                onAddressChange={address => handleAddressChangeAutocomplete({ address })}
                            />
                            {/* <AutocompleteAddress
                                address={{
                                    fullAddress: openedLocation[1]?.fullAddress,
                                    longitude: openedLocation[1]?.location?.longitude,
                                    latitude: openedLocation[1]?.location?.latitude,
                                }}
                                onAddressChange={address => handleAddressChangeAutocomplete({ address })}
                            /> */}
                            <Box mt={0.5} display="flex" sx={{ cursor: 'pointer' }} onClick={useCurrentLocation}>
                                <img src={icon} style={{ width: '15px', marginRight: '3px', cursor: 'pointer' }} />
                                <NormalText sx={{ color: 'primary.main', cursor: 'pointer' }}>
                                    {t('use_current_location')}
                                </NormalText>
                                {loading && <CircularProgress size={15} sx={{ mt: 0.5, ml: 1 }} />}
                            </Box>
                        </Box>

                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={4}>
                                <Box>
                                    <SmallText>
                                        <label>{t('streetNumber')}</label>
                                    </SmallText>
                                    <BasicInput
                                        placeholder={t('number', { count: 1 })}
                                        initialValue={openedLocation[1]?.streetNumber}
                                        onInput={handleInputChange('streetNumber')}
                                        value={openedLocation[1]?.streetNumber}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box>
                                    <SmallText>
                                        <label>{t('entrance')}</label>
                                    </SmallText>
                                    <BasicInput
                                        placeholder={t('entrance')}
                                        initialValue={openedLocation[1]?.entranceNumber}
                                        onInput={handleInputChange('entranceNumber')}
                                        value={openedLocation[1]?.entranceNumber}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box>
                                    <SmallText>
                                        <label>{t('floor', { count: 1 })}</label>
                                    </SmallText>
                                    <BasicInput
                                        placeholder={t('floor', { count: 1 })}
                                        initialValue={openedLocation[1]?.floor}
                                        onInput={handleInputChange('floor')}
                                        value={openedLocation[1]?.floor}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <SmallText>
                                        <label>{t('doorPin')}</label>
                                    </SmallText>
                                    <BasicInput
                                        placeholder={t('doorPin')}
                                        initialValue={openedLocation[1]?.doorPin}
                                        onInput={handleInputChange('doorPin')}
                                        value={openedLocation[1]?.doorPin}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <SmallText>
                                        <label>{t('apartment')}</label>
                                    </SmallText>
                                    <BasicInput
                                        placeholder={t('apartment')}
                                        initialValue={openedLocation[1]?.apartmentNumber}
                                        onInput={handleInputChange('apartmentNumber')}
                                        value={openedLocation[1]?.apartmentNumber}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2} display="flex" p={2} pb={2} justifyContent="space-around">
                        <PrimaryButton onClick={handleSave}>{t('save')}</PrimaryButton>
                        <Box ml={2}>
                            <BaseButton variant="secondary" onClick={handleClose}>
                                {t('close')}
                            </BaseButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ModalPopup>
    );
};

export default UserAddressMap;
